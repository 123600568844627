<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allContracts"
      hide-default-footer
      @click:row="rowClick"
      item-key="id"
      no-data-text="Malumot kiritilmagan"
      single-select
      class="row-pointer fixed-header"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          allContracts
            .map(function (x) {
              return x.id
            })
            .indexOf(item.id) + 1
        }}
      </template>
      <template v-slot:[`item.client_name`]="{ item }">
        <div class="w-200px">
          {{ item.client_name }}
        </div>
      </template>

      <template v-slot:[`item.start_date`]="{ item }">
        <div class="w-200px">
          {{ item.start_date | formatDate }}
        </div>
      </template>

      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | formatDate }}
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <div v-if="item.is_active">
          <span class="label label-success label-dot"></span>&nbsp;
          <span class="font-weight-bold text-success">Active</span>
        </div>
        <div v-else>
          <span class="label label-primary label-dot"></span>&nbsp;
          <span class="font-weight-bold text-danger">Offline</span>
        </div>
      </template>
      <template v-slot:[`item.parent_contract`]="{ item }">
        <div
          class="payment__name"
          v-if="item.parent_contract == null"
          :class="item.parent_contract_color"
        >
          <span
            :class="[item.parent_contract_label]"
            class="label label-dot"
          ></span>
          Kontrakt
        </div>
        <div v-else class="payment__name" :class="item.parent_contract_color">
          <span
            :class="[item.parent_contract_label]"
            class="label label-dot"
          ></span>
          Dop Kontrakt
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <ContractsAction :index="item"></ContractsAction>
      </template>
    </v-data-table>

    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import ContractsAction from '@/view/content/dropdown/ContractsAction.vue'
export default {
  components: { ContractsAction },
  data: () => ({
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    perPage: 10,
    currentPage: 1,
    singleSelect: true,
    selected: []
  }),
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.CLIENT'),
          value: 'client_name'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRACT'),
          value: 'contract_number'
        },
        {
          text: this.$t('TABLE_HEADER.START_DATE'),
          value: 'start_date'
        },
        {
          text: this.$t('TABLE_HEADER.END_DATE'),
          value: 'end_date'
        },
        {
          text: this.$t('TABLE_HEADER.INN'),
          value: 'client_inn'
        },
        {
          text: this.$t('TABLE_HEADER.AMOUNT'),
          value: 'amount'
        },
        {
          text: this.$t('TABLE_HEADER.CATEGORY'),
          value: 'parent_contract'
        },
        {
          text: this.$t('TABLE_HEADER.SHAPE'),
          value: 'payment_type'
        },
        {
          text: this.$t('TABLE_HEADER.TYPE'),
          value: 'contract_type'
        },

        {
          value: 'action',
          text: '',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.allContracts
    },
    allContracts() {
      var data = []
      data = this.$store.state.requests.allContracts.results
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.amount !== null) {
            element.amount = element.amount.toLocaleString('es-US')
          }
        })
        return data
      } else return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          // DO NOTHING BUT RESET IN CASE THERES JUST ONE CLICK
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200) // increase delay as you like
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        // COLUMN_DEFINITION[c]+=50
        // console.log('double', item.id)
        this.$router.push('/newcontractdetails/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      // console.log('123321', this.$store.state.requests.filterData.data)
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: value,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('contractsSearch', asd.payload)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      } else {
        console.log('111111')
        this.$store.dispatch('getAllContractsPag', value)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
tr.v-data-table__selected {
  background: #7d92f5 !important;
}
.v-data-table /deep/ .v-data-table__wrapper tbody tr.v-data-table__selected {
  background-color: #e8e8e8 !important;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}
.v-application .fixed-header {
  height: auto !important;
  overflow-y: unset !important;
}
</style>
